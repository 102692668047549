<template lang="pug">
.main-wrapper.funcionario-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Usuários</b>

		Dialog.dialogApagar(header='Remover usuário' :visible.sync='dialogApagar' :modal='true')
			p Deseja remover o usuário <b>{{ dialogApagar_data.first_name }} {{ dialogApagar_data.last_name }}</b>?
			.ta-center.my-4
				ProgressSpinner(v-if="waitingApagar" strokeWidth='6')
				Button.p-button-danger(v-else label='Remover' @click='remove(dialogApagar_data.id)')

		Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' v-if="waiting == false || estabelecimentoLoaded" mode="indeterminate")
			.p-grid.p-fluid.p-align-end
				.p-col-3
					label.form-label Nome:
					.p-inputgroup
						InputText(v-model='filters.name'
							@keyup='checkEmptyField("name")'
							@keyup.enter.native='applyFilters()')
				.p-col-3
					label.form-label Grupo(s):
					ProgressBar(v-if="waiting_grupos" mode="indeterminate")
					.p-inputgroup(v-else)
						MultiSelect(
							v-model='filters.lista_grupos'
							:options='options.grupos_multi'
							:filter='true'
							placeholder='TODOS'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							:showClear='true'
						)
				.p-col-3
					label.form-label Estabelecimento(s):
					ProgressBar(v-if="waiting_grupos" mode="indeterminate")
					.p-inputgroup(v-else)
						MultiSelect(
							:options='options.list_estabelecimento'
							placeholder='TODOS'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							:filter='true'
							v-model='filters.list_estabelecimento'
						)
				.p-col-3
					label.form-label Usuários Ativos:
					Dropdown( v-model='filters.is_active' :options='options.is_active'
						dataKey='value' optionLabel='label' optionValue='value' placeholder='- TODOS -' )
				.p-col-3
					.p-inputgroup
						Button(label='Aplicar filtros' style="margin-right: 15px; border-radius: 5px" icon='jam jam-search' :disabled='waiting' @click='applyFilters()')
						Button(label='Limpar filtros' style="border-radius: 5px" icon='jam jam-rubber' @click='clearFilters(0)')
		.ta-right.mt-4.mb-4
				Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/funcionario/salvar/0/')")
		ProgressBar(v-if="waiting" mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			DataView.dataview.my-2(:value="list" layout="grid")
				template(#grid="props")
					.p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.is_active }")
						Panel.panel-list.ta-center(:header="`${ props.data.first_name } ${ props.data.last_name }`")
							.ta-left
								p <b>Nome:</b> {{ props.data.first_name }}
								p <b>Sobrenome:</b> {{ props.data.last_name }}
								p <b>E-mail:</b> {{ props.data.email }}
								p <b>Usuário:</b> {{ props.data.username }}
							.ta-right.p-buttonset.mt-3
								Button.mr-1.p-button-raised.p-button-rounded(
									icon='jam jam-write'
									@click="$router.push(`/funcionario/salvar/${ props.data.id }/`)"
								)
								Button.p-button-raised.p-button-rounded.p-button-danger(
									v-if="props.data.is_active" icon='jam jam-minus-circle'
									@click="dialogApagar_data = props.data; dialogApagar = true"
								)
			Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

			Panel.datatable(header='Lista de usuário')
				DataTable(:value="list" @sort="onSort($event)" :sortField="order.field" :sortOrder="order.sortOrder")
					Column(field='first_name' header='Nome' sortable)
					Column(field='last_name' header='Sobrenome' sortable)
					Column(field='username' header='Usuário' sortable)
					Column(field='email' header='E-mail' sortable)
						template(#body='props')
							div(style="overflow-x: scroll; max-width: 250px; height: 60px;")
								span(v-tooltip.top="props.data.email" style='cursor: pointer;') {{ props.data.email_f }}
					Column(headerStyle='width: 10%;' field='pix' header='PIX' sortable)
					Column(field='grupos' header='Grupos' sortable)
						template(#body='props')
							.funcionario-listar.table-lista
								.ta-center(v-if="checkGrupos(props.data.grupos)")
									h5 &#x2022; Administrador Medclub
								.ta-center(v-else v-for="grupo in props.data.grupos")
									h5 &#x2022; {{ grupo.nome_grupo }}
					Column(field='estabs' header='Estabelecimentos' sortable)
						template(#body='props')
							.funcionario-listar.table-lista
								.ta-center(v-if="checkGrupos(props.data.grupos)")
									h5 &#x2022; Administrador Medclub
								.ta-center(v-else v-for="estab in props.data.estabs")
									h5 &#x2022; {{ estab.nm_fantasia }}
					Column(headerStyle='width: 5%; text-align: center')
						template(#header) Ações
						template(#body='props')
							.ta-center
								Button.p-button-raised.p-button-rounded.mr-1(
									v-tooltip.top="'Editar'"
									icon="jam jam-write"
									@click="$router.push(`/funcionario/salvar/${ props.data.id }/`)"
								)
								Button.p-button-raised.p-button-rounded.p-button-danger.mr-1(
									v-tooltip.top="'Apagar'"
									icon="jam jam-minus-circle"
									@click="dialogApagar_data = props.data; dialogApagar = true"
								)
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

			
</template>

<style lang="scss">
	.funcionario-listar {
		.table-lista {
			overflow-y: scroll;
			max-height: 150px;
		}
		.dataview {
			@media all and (min-width: 577px) {
				display: none;
			}
		}
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
			.cell {
				padding: 16px 0;
				text-align: center;
				&.ex  {  background-color: #e4f8e1; }
				&.a  { background-color: #faf3dd; }
			}
		}
		.dialogApagar {
			.p-progress-spinner {
				width: 29px;
				height: auto;
			}
		}
		.disabled {
			p { color: #aaa; }
			.p-panel-titlebar {
				span { color: #aaa; }
			}
			.p-panel-content {
				background-color: #f4f4f4 !important;
			}
		}
	}
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
	import DataView from 'primevue/dataview'
	import Panel from 'primevue/panel'
	import Button from 'primevue/button'
	import ProgressBar from 'primevue/progressbar'
	import Dialog from 'primevue/dialog'
	import Tooltip from 'primevue/tooltip'
	import InputText from 'primevue/inputtext'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Paginator from 'primevue/paginator'
	import ProgressSpinner from 'primevue/progressspinner'
	import MultiSelect from 'primevue/multiselect'
	import Dropdown from 'primevue/dropdown'
	import { Funcionario, Estabelecimento } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
	import * as _ from 'lodash'
// import { instances } from 'chart.js'
	export default {
		beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'funcionario') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
		created () {
			this.getEstabelecimentos();
			this.getGrupos({'clean': 'true'});
		},
		components: {
			DataView, Panel, Button, ProgressBar, Dialog, InputText, 
				Paginator, DataTable, Column, Tooltip, ProgressSpinner, MultiSelect, Dropdown
		}, 
		directives: { tooltip: Tooltip },
		data () {
			return {
				list: [],
				dialogApagar: false,
                windowInnerWidth: window.innerWidth,
				dialogApagar_data: {},
				waiting: false,
				waitingApagar: false,
				estabelecimentoLoaded: false,
				waiting_grupos: false,
				waiting_active: false,
				waiting_estabs: false,
				filters: {
					name: '',
					lista_grupos: null,
					list_estabelecimento: [],
					is_active: null,
				},
				options: {
					list_estabelecimento: [],
					lista_grupos: null,
					grupos_multi: [],
					is_active: [
						{ value: null, label: '- TODOS -' },
						{ value: 'true', label: 'Ativos' },
						{ value: 'false', label: 'Inativos' }
					],
				},
				paginator: {
					page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
                order: {
                    field: 'first_name',
                    sortOrder: 1
                },
			}
		},
		methods: {
			getEstabelecimentos(){
				this.waiting_estabs = true
				Estabelecimento.findAllClean({meus_estabelecimentos: true}).then(response => {
					this.waiting_estabs = false
					if (response.status == 200) {
						response.data.forEach(e => {
							this.options.list_estabelecimento.push({ text: e.nm_fantasia, value: e.id })
						})
						this.estabelecimentoLoaded = true
					}
				})
			},
			getList (params) {
				let vm = this
				vm.waiting = true
				Funcionario.findAll(params).then(response => {
					if (response.status == 200) {
						this.paginator.count = response.data.count
						response.data.results.forEach(funcionario => {
							if (funcionario.email) {
								let strMaxLength = 44
								funcionario.email_f = funcionario.email.length > strMaxLength ?
									`${ funcionario.email.substr(0, strMaxLength) }...` : funcionario.email
							}
						})
						vm.list = response.data.results
					}
					vm.waiting = false
				})
			},
			getGrupos(params){
				this.waiting_grupos = true;
				this.options.grupos_multi = [];
				Funcionario.findAllGrupos(params).then(response => {
					if (response.status === 200) {
						this.options.grupos_multi.push({ text: 'TODOS', value: null })
						response.data.forEach(e => {
							this.options.grupos_multi.push({ text: e.nm_grupo, value: e.id })
						})
					} else { console.log(response) }
					this.waiting_grupos = false
				})
			},
			checkGrupos(grupos) {
				for(let g in grupos) { if (grupos[g].id_grupo == 1) return true }
				return false
			},
			applyFilters () {
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`
				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filters).forEach((key) => { 
					if (this.filters[key]) {
						if(!_.isArray(this.filters[key]) || (_.isArray(this.filters[key]) && !_.isEmpty(this.filters[key]))){
							params[key] = this.filters[key] 	
						}	
					} 
				})
			this.getList(params)
			},
			clearFilters (table) {
				this.paginator.page = 1
				Object.keys(this.filters).forEach(k => { if (k !== 'cd_guia_tipo') this.filters[k] = null })
				this.applyFilters(table)
			},
			checkEmptyField (field) {
				if (! this.filters[field].length) this.applyFilters()
			},
			onPage (ev) {
				this.paginator.page = ev.page + 1
				this.applyFilters()
			},
            onSort(ev) {
                this.order.field = ev.sortField
				this.order.sortOrder = ev.sortOrder
				this.applyFilters()
            },
			remove (id) {
				this.waitingApagar = true
				Funcionario.remove(id).then(response => {
					this.waitingApagar = false
					if (response.status == 200) {
						this.$toast.info('Usuário removido', { duration: 3000 })
						this.applyFilters()
						this.dialogApagar = false
					} else {
						this.$toast.error(response.data.detail, { duration: 3000 })
					}
				})
			},
		}
	}
</script>